<template>
  <form @submit.prevent="setPassword">
    <div class="modal-card">
      <header class="modal-card-head has-background-primary">
        <p class="modal-card-title has-text-white has-text-centered">
          {{ title }}
        </p>
      </header>

      <section class="modal-card-body">
        <b-field label="Current password">
          <b-input
            v-model="oldPassword"
            type="password"
            autocomplete="password"
            placeholder="Please type your current password"
          />
        </b-field>

        <create-password
          ref="createPassword"
          :user-id="userId"
          confirm
          expand
          @validated="password = $event"
        />
      </section>

      <footer class="modal-card-foot has-content-justified-end">
        <button
          :class="{ 'is-loading': processing }"
          :disabled="formIsDisabled"
          type="submit"
          class="is-pulled-right button is-success"
        >
          Change password
        </button>
      </footer>
    </div>
  </form>
</template>

<script>
export default {
  name: "ChangePasswordModal",
  components: {
    "create-password": () => import("@shared/account/_createPassword")
  },
  props: {
    userId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: "Set new password"
    }
  },
  data() {
    return {
      password: "",
      processing: false,
      oldPassword: ""
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"](this.userId);
    },
    formIsValid() {
      return !!this.oldPassword.length && !!this.password.length;
    },
    formIsDisabled() {
      return !this.formIsValid || this.processing;
    }
  },
  methods: {
    setPassword() {
      this.processing = true;

      this.$store
        .dispatch("reseller/changePassword", {
          userId: this.userId,
          password: this.password,
          oldPassword: this.oldPassword
        })
        .then(result => {
          this.processing = false;
          this.$emit("changed");

          if (result && result.message) {
            this.$toast.open({
              message: `${result.message}`,
              type: "is-success"
            });
          }
        })
        .catch(error => {
          this.processing = false;
          if (error && error.message) {
            this.$toast.open({
              message: `${error.message}`,
              type: "is-danger"
            });
          }
        });
    }
  }
};
</script>
